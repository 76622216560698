import React from 'react'

import {} from './notFoundController'

export class NotFound extends React.Component {
  render() {
    return (
      <div>
        <h1>404</h1>
        <p>Not Found</p>
      </div>
    )
  }
}
